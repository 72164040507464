import { Injectable } from '@angular/core';
import { CanMatch, GuardResult, MaybeAsync, Route, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { NgxPermissionsService } from "ngx-permissions";

interface Data {
  permissions: {
    only: string[],
    message: string,
    redirectTo: string,
  }
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanMatch {
  constructor(
    private notifyService: ToastrService,
    private permissionsService: NgxPermissionsService,
    private router: Router
  ) {
  }

  canMatch(route: Route): MaybeAsync<GuardResult> {
    const { permissions: { only, message, redirectTo } } = route.data as Data;
    return this.permissionsService.hasPermission(only)
      .then(passed => {
        if (passed) return true;
        this.notifyService.error(message, 'Access denied');
        void this.router.navigate([ redirectTo ]);
        return false;
      });
  }

}
