import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AccountDealsLayoutStyleService {
  styleClassSubject$ = new BehaviorSubject<string[]>([]);
  styleClass$ = this.styleClassSubject$.asObservable();

  addClass(className: string): void {
    const styleClasses = this.styleClassSubject$.value;
    this.styleClassSubject$.next([...styleClasses, className]);
  }

  removeClass(className: string): void {
    const styleClasses = this.styleClassSubject$.value;
    this.styleClassSubject$.next([...styleClasses.filter(styleClass => styleClass !== className)]);
  }
}
