import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { Firestore } from '@angular/fire/firestore';
import { filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectCurrentUser } from '../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { getRelatedUserIds$ } from '../../data-access/users';
import { getAccounts$, getAccountsForUserIds$, getAllCompanyAccounts$, } from '../../data-access/accounts';
import { isArray, isString } from 'lodash';
import { UserTypes } from '../../users/user.types';
import { handleSchema } from '../../models/handle-schema';
import { Account, accountSchema } from '../../models/account';

@Injectable({
  providedIn: 'root',
})
export class GetAccountsService {
  constructor(private firestore: Firestore, private store$: Store) {
  }

  get(userId?: string | string[]): Observable<Account[]> {
    return this.store$.pipe(
      select(selectCurrentUser),
      filter(inputIsNotNullOrUndefined),
      take(1),
      switchMap(currentUser => {
        const companyId = currentUser.accountId;

        // If current user is owner of company and userId is not specified - return all accounts of company
        if (!userId && currentUser.type === UserTypes.OWNER) {
          return getAllCompanyAccounts$(this.firestore, currentUser.accountId).pipe(
            handleSchema(accountSchema, currentUser),
          );
        }

        // Todo: Remove get accounts by id or ids as we always gets it related or company scope?
        // Get for single user
        if (isString(userId)) {
          return getAccounts$(this.firestore, companyId, userId).pipe(
            handleSchema(accountSchema, currentUser),
          );
        }

        // Get for multiple users
        if (isArray(userId)) {
          return getAccountsForUserIds$(this.firestore, companyId, userId).pipe(
            handleSchema(accountSchema, currentUser),
          );
        }

        // Get all related users
        return getRelatedUserIds$(this.firestore, currentUser).pipe(
          switchMap(userIds => getAccountsForUserIds$(this.firestore, companyId, userIds)),
          handleSchema(accountSchema, currentUser),
        );
      }),
    );
  }
}
