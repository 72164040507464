import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GetDealState } from './get-deal.types';

const getDealFeatureSelector = createFeatureSelector<GetDealState>('getDeal');

export const selectGetDealLoading = createSelector(
  getDealFeatureSelector,
  state => state.loading
);

export const selectGetDealLoaded = createSelector(
  getDealFeatureSelector,
  state => state.loaded
);

export const selectGetDeal = createSelector(
  getDealFeatureSelector,
  state => state.data
);

export const selectGetDealError = createSelector(
  getDealFeatureSelector,
  state => state.error
);
