import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  getDealAction,
  getDealFailureAction,
  getDealSuccessAction
} from './get-deal.actions';
import { GetDealService } from './get-deal.service';
import { updateDealSuccessAction } from '../update-deal/update-deal.actions';

@Injectable()
export class GetDealEffects {
  getDeal$ = createEffect(() => this.actions$.pipe(
    ofType(getDealAction),
    switchMap(action =>
      this.getDealService.get(action.payload.id).pipe(
        map(data => getDealSuccessAction({ data })),
        catchError(error => of(getDealFailureAction({ error }))),
      )
    ),
  ));

  getDealUpdated$ = createEffect(() => this.actions$.pipe(
    ofType(updateDealSuccessAction),
    map(({ data }) =>
      getDealSuccessAction({ data })
    ),
  ));

  constructor(
    private actions$: Actions,
    private getDealService: GetDealService,
  ) {
  }
}
