import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';

import { Account } from "../../../models/account";

export const getAccountAction = createAction(
  ActionTypes.GET_ACCOUNT,
  props<{ id: string }>()
);

export const getAccountSuccessAction = createAction(
  ActionTypes.GET_ACCOUNT_SUCCESS,
  props<{ account: Account | null }>()
);

export const getAccountFailureAction = createAction(
  ActionTypes.GET_ACCOUNT_FAILURE,
  props<{ error: any }>()
);
