import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './get-deal.reducers';
import { GetDealEffects } from './get-deal.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetDealEffects ]),
    StoreModule.forFeature('getDeal', reducer),
  ]
})
export class GetDealModule {
}
