import { Action, createReducer, on } from '@ngrx/store';
import { GetDealState } from './get-deal.types';
import { getDealAction, getDealFailureAction, getDealSuccessAction } from './get-deal.actions';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const getDealReducer = createReducer<GetDealState>(
  initialState,
  on(
    getDealAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      data: null,
    })
  ),
  on(
    getDealSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    getDealFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetDealState, action: Action) => getDealReducer(state, action);
