import { Injectable } from '@angular/core';
import { Observable, switchMap, takeWhile } from 'rxjs';
import { UserService } from '../../auth/services/user.service';
import { Firestore } from '@angular/fire/firestore';
import { getAccountForId$ } from '../../data-access/accounts';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserCompanyId } from '../../current-user/store/current-user.selectors';
import { filter, map } from 'rxjs/operators';
import { inputIsNotNullOrUndefined, inputIsNotUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { Account } from "../../models/account";

@Injectable({
  providedIn: 'root',
})
export class GetAccountService {
  constructor(private userService: UserService, private firestore: Firestore, private store$: Store) {
  }

  get(id: string): Observable<Account | null> {
    return this.store$.pipe(
      select(selectCurrentUserCompanyId),
      filter(inputIsNotNullOrUndefined),
      switchMap(companyId => getAccountForId$(this.firestore, companyId, id)),
      map(account => account || null),
      // filter(inputIsNotUndefined),
      takeWhile(() => !!this.userService.currentUser),
    );
  }
}
