import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';

import { Deal } from '../../../models/deal';

export const getDealAction = createAction(
  ActionTypes.GET_DEAL,
  props<{ payload: { id: string } }>()
);

export const getDealSuccessAction = createAction(
  ActionTypes.GET_DEAL_SUCCESS,
  props<{ data: Deal | null }>()
);

export const getDealFailureAction = createAction(
  ActionTypes.GET_DEAL_FAILURE,
  props<{ error: unknown }>()
);
