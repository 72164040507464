import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';

import { Account } from "../../../models/account";

export const getChildAccountsAction = createAction(
  ActionTypes.GET_CHILD_ACCOUNTS,
  props<{ payload: { id: string } }>()
);

export const getChildAccountsSuccessAction = createAction(
  ActionTypes.GET_CHILD_ACCOUNTS_SUCCESS,
  props<{ data: Account[] }>()
);

export const getChildAccountsFailureAction = createAction(
  ActionTypes.GET_CHILD_ACCOUNTS_FAILURE,
  props<{ error: unknown }>()
);
