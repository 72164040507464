import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { UserService } from '../../auth/services/user.service';
import { LocalService } from '../../core/services/local.service';
import { map } from 'rxjs/operators';
import { FilterValue } from "../../data-access/filters";

export interface Pagination {
  size: number,
}

export interface LocalSettings {
  pagination?: Partial<{
    users: Pagination,
    usersReport: Pagination,
    accounts: Pagination,
    allAccounts: Pagination,
    contacts: Pagination,
    documents: Pagination,
    accountDocuments: Pagination,
    userDocuments: Pagination,
    categorySettings: Pagination,
    tagSettings: Pagination,
    dailyRouteAccounts: Pagination,
    tasks: Pagination,
    allTasks: Pagination,
    notes: Pagination,
    sharedAccounts: Pagination,
    reportResults: Pagination,
    events: Pagination,
    userActivityLogs: Pagination,
    accountActivityLogs: Pagination,
    emailTemplates: Pagination,
    assignmentRules: Pagination,
    deals: Pagination,
    accountDeals: Pagination,
  }>,
  filter?: Partial<{
    allAccounts: FilterValue[] | null,
    locations: FilterValue[] | null,
    dashboardEvents: FilterValue[] | null,
    deals: FilterValue[] | null,
    accountDeals: FilterValue[] | null,
  }>,
}

@Injectable({
  providedIn: 'root',
})
export class GetLocalSettingsService {

  constructor(private userService: UserService, private localStore: LocalService) {
  }

  get(): Observable<LocalSettings | null> {
    const currentUserId = this.userService.currentUser?.uid;

    if (!currentUserId) {
      throw new Error('Unexpected: Missed user ID');
    }

    console.log('currentUserId', currentUserId);

    return of(this.localStore.get(`repmove-${ currentUserId }`)).pipe(
      map(data => data ? (JSON.parse(data) as LocalSettings) : null),
      tap(data => console.log('settings: ', data)),
    );
  }
}
