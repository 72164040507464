import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { Subject } from 'rxjs';
import { BreadcrumbService } from '../../../layout/breadcrumb/breadcrumb.service';
import { PageMetaService } from '../../../core/services/page-meta.service';
import { FlexModule } from 'ngx-flexible-layout';
import { AsyncPipe } from '@angular/common';
import { DirectivesModule } from '../../../shared/directives/directives.module';
import { UiModule } from '../../../shared/ui/ui.module';
import { NgbNav, NgbNavItem, NgbNavLinkBase } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { selectGetDeal, selectGetDealLoading } from '../../store/get-deal/get-deal.selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { inputIsNotNull } from '../../../data-access/input-is-not-null-or-undefined';
import { GetDealModule } from '../../store/get-deal/get-deal.module';
import { getDealAction } from '../../store/get-deal/get-deal.actions';

@Component({
  selector: 'app-deal-details-layout',
  templateUrl: './deal-details-layout.component.html',
  styleUrls: [ './deal-details-layout.component.scss' ],
  standalone: true,
  imports: [
    FlexModule,
    AsyncPipe,
    DirectivesModule,
    UiModule,
    NgbNav,
    RouterLinkActive,
    NgbNavLinkBase,
    RouterLink,
    RouterOutlet,
    NgbNavItem,
    GetDealModule,
  ]
})
export class DealDetailsLayoutComponent implements OnInit, OnDestroy {
  isLoading$ = this.store$.pipe(
    select(selectGetDealLoading),
  );
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private pageMetaService: PageMetaService,
    private store$: Store,
  ) {
  }

  ngOnInit(): void {
    this.breadcrumbService.set(
      {
        name: 'Deals',
        list: [
          {
            caption: 'Deals',
            link: [ 'deals' ],
            active: false,
          }
        ]
      }
    );

    const paramMap$ = this.route.paramMap;
    if (!paramMap$) throw new Error('Unexpected: Missed queryParams');

    paramMap$.subscribe(queryParam => {
      const id = queryParam.get('id');
      if (!id) throw new Error('Unexpected: Missed deal id');
      this.store$.dispatch(getDealAction({
        payload: {
          id,
        },
      }));
    });

    this.store$.pipe(
      select(selectGetDeal),
      filter(inputIsNotNull),
      take(1),
      takeUntil(this.unsubscribe$),
    ).subscribe(deal => {
      this.pageMetaService.patchMeta({ accountName: deal.title });
      this.breadcrumbService.addToList(
        {
          caption: deal.title,
          link: [ 'deals', deal._id ],
          active: true,
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.breadcrumbService.clear();
    this.pageMetaService.clearMeta();
  }
}
