import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { Deal } from '../../../models/deal';
import { UpdateDealPayload } from './update-deal.service';

export const updateDealAction = createAction(
  ActionTypes.UPDATE_DEAL,
  props<{ payload: UpdateDealPayload }>()
);

export const updateDealSuccessAction = createAction(
  ActionTypes.UPDATE_DEAL_SUCCESS,
  props<{ data: Deal }>()
);

export const updateDealFailureAction = createAction(
  ActionTypes.UPDATE_DEAL_FAILURE,
  props<{ error: unknown }>()
);
