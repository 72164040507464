import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Deal, dealSchemaWithId } from '../../../models/deal';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { decodeZodData } from '../../../models/handle-schema';

@Injectable({
  providedIn: 'root',
})
export class GetDealService {
  constructor(private http: HttpClient) {
  }

  get(id: string): Observable<Deal> {
    return this.http.get<unknown>(`${ environment.apiV2.dealsURL }/${ id }`).pipe(
      decodeZodData(dealSchemaWithId),
    )
  }
}
