import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { Account } from "../../models/account";

@Injectable({
  providedIn: 'root',
})
export class GetChildAccountsService {
  constructor(private http: HttpClient) {
  }

  get(id: string): Observable<Account[]> {
    return this.http.get<Account[]>(`${ environment.apiURLV2 }/accounts/get-child-accounts/${ id }`);
  }
}
