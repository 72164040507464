import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserActivityLogStats } from '../../data-access/user-activity-logs';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

export type GetUsersActionEventStatsPayload = {
  dateRange: {
    from: number,
    to: number,
  },
}

@Injectable({
  providedIn: 'root',
})
export class GetUsersActivityLogStatsService {
  constructor(private http: HttpClient) {
  }

  get(payload: GetUsersActionEventStatsPayload): Observable<UserActivityLogStats[]> {
    return this.http.get<UserActivityLogStats[]>(`${ environment.apiURLV2 }/users/activity`, {
      params: new HttpParams().appendAll({
        from: payload.dateRange.from,
        to: payload.dateRange.to,
      }),
    });
  }
}
