import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, switchMap, tap } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getRelatedUsersAction } from '../../users/get-related-users/store/get-related-users.actions';
import { LoadingAppService } from '../services/loading-app.service';
import {
  selectGetCompanySettingsLoaded
} from "../../company-settings/get-company-settings/store/get-company-settings.selectors";

@Injectable({ providedIn: 'root' })
export class RelatedUsersResolver implements Resolve<boolean> {
  constructor(
    private store$: Store,
    private loadingAppService: LoadingAppService,
  ) {
  }

  resolve(): Observable<boolean> {
    this.loadingAppService.setState(true);
    return this.store$.pipe(
      select(selectGetCompanySettingsLoaded),
      filter(loaded => loaded),
      take(1),
      tap(() => this.store$.dispatch(getRelatedUsersAction())),
      switchMap(() =>
        this.store$.pipe(
          select(selectGetCompanySettingsLoaded),
          filter(loaded => loaded),
          take(1),
        )
      ),
    );
  }
}
