@if (!!(isLoading$ | async)) {
  <div fxLayout="column" fxLayoutAlign="center center" appFullHeightContent>
    <app-loading></app-loading>
  </div>
} @else {
  <div fxLayout="column" fxLayoutGap="1.875rem">
    <ul ngbNav [activeId]="route.fragment | async" class="rmv-tabs">
      <li class="rmv-tabs__item" ngbNavItem="details" routerLinkActive="--active">
        <a class="rmv-tabs__link" ngbNavLink [routerLink]="['details']">Details</a>
      </li>
      <li class="rmv-tabs__item" ngbNavItem="details" routerLinkActive="--active">
        <a class="rmv-tabs__link" ngbNavLink [routerLink]="['tasks']">Tasks</a>
      </li>
    </ul>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
}
